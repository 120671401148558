// core
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
// components
import Preloader from '../preloader';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
// instruments
import { getQueryParameterByName } from '../../helpers';
// styles
import './pageSuccess.scss';

const PageSuccess: FC = () => {
  // hooks
  const dispatch = useDispatch();
  const sessionIdUrl = getQueryParameterByName('session');
  const sessionIdSs = window.sessionStorage.getItem('compariumSessionID');

  if (sessionIdUrl !== sessionIdSs) {
    navigate('/');

    return <Preloader />;
  }

  dispatch(uiActions.toggleThanksForPurchasePopup(true));

  return <div className="success"></div>;
};

export default PageSuccess;
